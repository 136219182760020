import React from "react";
import { useForm, Controller } from "react-hook-form";
import { Container } from "react-bootstrap";
import { faKey, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./css/login-form.css";
import LoginPost from "./ajaxcall/LoginPost";
import { TokenContext } from "../contexts/TokenContext";
import { Redirect } from "react-router-dom";

interface IProps {
    email: string,
    password: string
}

const LoginForm = () => {

    const { control, handleSubmit } = useForm()
    //Token Context
    const { infos, setInfos }: any = React.useContext(TokenContext)

    const onSubmit = (data: IProps) => {
        LoginPost(data)
            .then((result) => {
                if (result.token === "") {
                    alert("Email o password errati!");
                    setInfos({
                        email    : "",
                        name     : "",
                        surname  : "",
                        token    : "",
                        username : "",
                        isLogged : false
                    })
                } else {
                    setInfos({
                        isLogged: result.isLogged,
                        username: result.username,
                        email:    result.email,
                        token:    result.token,
                        name:     result.name,
                        surname:  result.surname
                    })
                }
            })
    }

    return (
        <Container>
            {(infos.isLogged) ? (
                <Redirect to={"/"} />
            ) : (
            <div className="row flex-xl-nowrap">
                <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                    <div className="card card-signin my-5">
                        <div className="card-body">
                            <h5 className="card-title text-center">ENTRA</h5>
                            <div className="mt-5"></div>
                            <form className="form-signin" onSubmit={handleSubmit(onSubmit)}>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1"><FontAwesomeIcon icon={faEnvelope} /></span>
                                    </div>
                                    <Controller
                                        name={"email"}
                                        defaultValue={""}
                                        control={control}
                                        render={({ onChange, value }) => (
                                            <input
                                                name="email"
                                                type="text"
                                                className="form-control"
                                                placeholder="Indirizzo Email"
                                                aria-label="Indirizzo Email"
                                                aria-describedby="basic-addon1"
                                                onChange={value => onChange(value)}
                                                value={value}
                                                required
                                            />
                                        )}
                                    />
                                </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1"><FontAwesomeIcon icon={faKey} /></span>
                                    </div>
                                    <Controller
                                        name={"password"}
                                        defaultValue={""}
                                        control={control}
                                        render={({ onChange, value }) => (
                                            <input
                                                name="password"
                                                type="password"
                                                className="form-control"
                                                placeholder="Password"
                                                aria-label="Password"
                                                aria-describedby="basic-addon1"
                                                onChange={value => onChange(value)}
                                                value={value}
                                                required
                                            />
                                        )}
                                    />
                                </div>
                                <hr className="my-4" />
                                <button className="btn btn-lg btn-primary btn-block text-uppercase" type="submit">Sign in</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div> )}
        </Container>
    )
}

export default LoginForm;