import React from "react";
import { TokenContext } from "../contexts/TokenContext";
import { Redirect } from "react-router-dom";
import TableLink from "../table/TableLink";
import { Container, Row } from "react-bootstrap";

const Dashboard = () => {

    //Token Context
    const { infos, setInfos }: any = React.useContext(TokenContext)

    return(
        <div>
            {(infos.isLogged) ? (
            <Container className={"fluid mb"}>
                <Row className={"flex-xl-nowrap"}>
                    <TableLink />
                </Row>
            </Container>
        ) : (
            <Redirect to={"/login"} />
        )}
        </div>
    )
}

export default Dashboard;