import React from "react";
import { DataGrid, RowsProp, ColDef, ValueFormatterParams, CellParams, GridApi, ApiRef, useApiRef } from "@material-ui/data-grid";
import { Grid, Button } from "@material-ui/core";
import { TokenContext } from "../contexts/TokenContext";
import FetchLinksList from "../link/ajaxcall/FetchLinksList";
import DeleteLink from "../link/ajaxcall/DeleteLink";
import NewLink from "./NewLink";
import { FormLabel, Row } from 'react-bootstrap';
import './css/table.css';

interface ILink {
    id: string,
    short_link: string,
    long_link: string
}

interface ILinks {
    links: ILink[]
}

const TableLink = () => {

    let apiRef1 = React.useRef<GridApi>(null);
    let api:ApiRef = useApiRef()

    //Token Context
    const { infos, setInfos }: any = React.useContext(TokenContext)

    //Links Array
    const [link, setLink] = React.useState<ILinks>({
        links: []
    })

    //Loading var
    const [loading, setLoading] = React.useState<boolean>(true)

    //DeleteHandler
    const deleteHandler = ( short_link: CellParams) => {
        if(short_link.value !== undefined && short_link.value !== null) {
            DeleteLink({
                token: infos.token,
                link:  short_link.value.toString()
            }).then((response) =>{
                if(response) {
                    alert("Short Link eliminato!")
                    const new_links = link.links.filter( (item) => {
                        if(item.short_link !== short_link.value?.toString())
                            return item
                    })
                    setLink({
                        links: new_links
                    })
                
                } else {
                    alert("Errore durante l'eliminazione")
                }
            })
        }
    }

    //Update Data Handler
    const UpdateDataHandler = (links:ILinks) => {
        setLink({
            links: links.links
        })
        document.location.reload()
    }

    const fetchLinks = () => {
        FetchLinksList({
            token: infos.token
        }).then(response => {
            if(response.results != null) {
                setLink({ links: response.results })
            }
            setLoading(false)
        })
    }

    React.useEffect(fetchLinks, [])

    const columns: ColDef[] = [
        { field: 'short_link', headerName: 'Short Link', width: 180,
            renderCell: ( params: ValueFormatterParams ) => (
                <Grid container spacing={1}>
                    <Grid container item xs={6} spacing={2}>
                        <span
                            style={{marginLeft: 16}}
                        >{params.value}
                        </span>
                    </Grid>
                    <Grid container item xs={6}>
                        <Button
                            variant = "contained"
                            color   = "primary"
                            size    = "small"
                            style   = {{ marginLeft: 16 }}
                            onClick = { 
                                () => deleteHandler(params)
                            }
                        >Elimina</Button>
                    </Grid>
                </Grid>
            )
        },
        { field: 'long_link', headerName: 'Long Link', width: 600 },   
    ];

    let rows: RowsProp = link.links

    return (
        <Grid item xs={12} className={"p-3"}>
            <FormLabel><h2>Gestione ShortLink</h2></FormLabel>
            <Row className={"mb-3"}>
                <NewLink 
                    links={ link }
                    callback={ (links) => UpdateDataHandler(links) }
                />
            </Row>
            <FormLabel>Lista degli short link attivi</FormLabel>
            <DataGrid
                pagination
                rows={rows}
                columns={columns}
                autoHeight={true}
                loading={loading}
                checkboxSelection={false}        
            />
        </Grid>
    )
}

export default TableLink;