import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomePage from './components/homepage/HomePage';
import Dashboard from './components/dashboard/Dashboard';
import LoginForm from './components/login/LoginForm';
import NavBar from './components/navbar/NavBar';
import {TokenContext} from './components/contexts/TokenContext';

interface IInfos {
  isLogged:       boolean,
  username:       string,
  email:          string,
  token:          string,
  name:           string,
  surname:        string,
  registration?:  boolean,
  loggin?:        boolean
}

function App() {

  let infoStorage = JSON.parse((JSON.parse(JSON.stringify(localStorage.getItem("credential")))));
  if(infoStorage === null) {
      infoStorage = ({
          isLogged:   false,
          username:   "",
          email:      "",
          token:      "",
          name:       "",
          surname:    "",
      })
  }

  const [infos, setInfos] = React.useState<IInfos>({
    isLogged:       infoStorage.isLogged,
    username:       infoStorage.username,
    email:          infoStorage.email,
    token:          infoStorage.token,
    name:           (infoStorage.name)    ? infoStorage.name : "",
    surname:        (infoStorage.surname) ? infoStorage.surname : ""
  })

  return (
    <TokenContext.Provider value = {{infos, setInfos}} >
      <NavBar />
      <Router>
        <Switch>
          <Route exact path={"/"}>
            <HomePage />
          </Route>
          <Route exact path={"/dashboard"}>
            <Dashboard></Dashboard>
          </Route>
          <Route exact path={"/login"}>
            <LoginForm></LoginForm>
          </Route>
          <Route path={"*"}>
          </Route>
        </Switch>
      </Router>
    </TokenContext.Provider>
  );
}

export default App;
