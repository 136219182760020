import Axios from "axios";

interface IProps {
    email: string,
    password: string
}

interface IRes {
    email: string,
    name: string,
    surname: string,
    token: string,
    username: string,
    isLogged: boolean
}

async function LoginPost(props:IProps) {

    let res:IRes = {
        email:    "",
        name:     "",
        surname:  "",
        token:    "",
        username: "",
        isLogged: false
    }
    try {
        await
            Axios.request({
                method: 'POST',
                url: 'https://soslink.it/api/login',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json; charset=UTF-8",
                },
                data: JSON.stringify({
                    email: props.email,
                    password: props.password
                })
            }).then((response) => {
                res.email    = response.data.email
                res.name     = response.data.name
                res.surname  = response.data.surname
                res.token    = response.data.token
                res.username = response.data.username
                res.isLogged = response.data.isLogged
                if(res.isLogged) {
                    localStorage.setItem("credential", JSON.stringify({
                        email    : response.data.email,
                        name     : response.data.name,
                        surname  : response.data.surname,
                        token    : response.data.token,
                        username : response.data.username,
                        isLogged : response.data.isLogged
                    }))
                } else {
                    localStorage.removeItem("credential")
                }
            })
    } catch (err) {
        console.log(err);
    }

    return(res) 
}

export default LoginPost;