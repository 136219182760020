import React from "react";
import { Nav, NavItem, Navbar } from "react-bootstrap";
import { TokenContext } from "../contexts/TokenContext";

const NavBarNotLogged = () => {

    //Token Context
    const { infos, setInfos }: any = React.useContext(TokenContext)

    return (
        <div>
            {
                !infos.isLogged ? (
                    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                        <Navbar.Brand href="/">
                            <img
                                alt=""
                                src="https://www.flaticon.com/svg/static/icons/svg/748/748854.svg"
                                width="30"
                                height="30"
                                className="d-inline-block align-top"
                            />{' '}
                                SOS Link
                            </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className={"mr-auto"}>
                                <NavItem>
                                </NavItem>
                            </Nav>
                            <Nav>
                                <NavItem style={{ marginRight: "2em" }}>
                                    <Navbar.Text>{infos.name} {infos.surname}</Navbar.Text>
                                </NavItem>
                                <NavItem>
                                    <a 
                                        href={"/login"}
                                        type={"button"}
                                        className={"btn btn-success"}
                                    >LOGIN</a>
                                </NavItem>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                ) : (null)
            }
        </div>
    )
}

export default NavBarNotLogged;