import Axios from "axios";

interface IProps {
    token: string
}

interface IRes {
    results: []
}

async function FetchLinksList(props:IProps) {

    const res:IRes = {
        results: []
    }

    try {
        await
            Axios.request({
                method: 'POST',
                url: 'https://soslink.it/api/get_links_list',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json; charset=UTF-8",
                },
                data: JSON.stringify({
                    token: props.token
                })
            }).then((response) => {
                res.results = response.data["Results"]
                // console.log(response)
            })
    } catch (err) {
        console.log(err);
    }

    return(res) 
}

export default FetchLinksList;