import React from "react";
import { Nav, NavItem, Navbar, Button } from "react-bootstrap";
import { TokenContext } from "../contexts/TokenContext";
import LogOut from "../login/ajaxcall/LogOut";

const NavBarLogged = () => {

    //Token Context
    const { infos, setInfos }: any = React.useContext(TokenContext)

    const logOutHandler = () => {
        LogOut({
            token: infos.token
        }).then((result) => {
            setInfos({
                email: "",
                name: "",
                surname: "",
                token: "",
                username: "",
                isLogged: false
            })
        })
    }

    return (
        <div>
            {
                infos.isLogged ? (
                    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                        <Navbar.Brand href="/">
                            <img
                                alt=""
                                src="https://www.flaticon.com/svg/static/icons/svg/748/748854.svg"
                                width="30"
                                height="30"
                                className="d-inline-block align-top"
                            />{' '}
                                SOS Link
                            </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className={"mr-auto"}>
                                <NavItem>
                                    <Nav.Link href={"/dashboard"}>Dashboard</Nav.Link>
                                </NavItem>
                            </Nav>
                            <Nav>
                                <NavItem style={{ marginRight: "2em" }}>
                                    <Navbar.Text>{infos.name} {infos.surname}</Navbar.Text>
                                </NavItem>
                                <NavItem>
                                    <Button
                                        variant={"danger"}
                                        onClick={() => logOutHandler()}
                                    >LOGOUT</Button>
                                </NavItem>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                ) : (null)
            }
        </div>  
    )
}

export default NavBarLogged;