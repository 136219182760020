import React from "react";
import NavBarNotLogged from "./NavBarNotLogged";
import NavBarLogged from "./NavBarLogged";

const NavBar = () => {

    return (
        <>
            <NavBarLogged />
            <NavBarNotLogged />
        </>
    )
}

export default NavBar;