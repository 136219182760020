import { requirePropFactory } from "@material-ui/core";
import React from "react";
import { Form, InputGroup, Button, FormLabel } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { TokenContext } from "../contexts/TokenContext";
import AddNewLink from "../link/ajaxcall/AddNewLink";

interface IProps {
    links: ILinks,
    callback: (links:ILinks) => void
}

interface IPropsCall {
    token: string,
    newlink: string
}

interface ILink {
    id: string,
    short_link: string,
    long_link: string
}

interface ILinks {
    links: ILink[]
}

const NewLink = (props:IProps) => {

    const { control, handleSubmit } = useForm()

    //Token Context
    const { infos, setInfos }: any = React.useContext(TokenContext)

    //Submit
    const onSubmit = (data:IPropsCall) => {
        data.token = infos.token
        AddNewLink(data).then( (response) => {
            if(response !== null) {
                alert("Il link è stato aggiunto")
                props.links.links.push({
                    id: response.id,
                    short_link: response.short_link,
                    long_link: response.long_link
                })
                props.callback(props.links)
            }
            else   
                alert("Problema con l'inserimento del link o link già presente")
        })
    }

    return (
        <Form className={"col-sm-12"} onSubmit={handleSubmit(onSubmit)} >
            <FormLabel>Insersci un nuovo Link</FormLabel>
            <InputGroup>
                <InputGroup.Prepend>
                    <InputGroup.Text>
                        Link
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <Controller
                    name={"newlink"}
                    defaultValue={""}
                    control={control}
                    render={({ onChange, value }) => (
                        <input
                            name="newlink"
                            type="text"
                            className="form-control"
                            placeholder="https://sitename.com/"
                            aria-label="Url"
                            aria-describedby="basic-addon1"
                            onChange={value => onChange(value)}
                            value={value}
                            required
                        />
                    )}
                />
                <Button
                    style={{marginLeft: "2rem"}}
                    type={"submit"}
                    variant={"primary"}
                >Aggiungi Link
                </Button>
            </InputGroup>
        </Form>
    )
}

export default NewLink;