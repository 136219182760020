import React from "react";
import "./css/homepage.css";
import "./css/nicepage.css";

const HomePage = () => {
    return (
        <div>
            <link id="u-theme-google-font" rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i|Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i" />
            <link id="u-page-google-font" rel="stylesheet" href="https://fonts.googleapis.com/css?family=Oswald:200,300,400,500,600,700|Fjalla+One:400" />
            <section className="u-align-center u-black u-clearfix u-section-1" id="carousel_14dd">
                <img className="u-expand-resize u-expanded-width u-image u-image-1" src="https://images03.nicepage.io/c461c07a441a5d220e8feb1a/bd42e19406b8580098a14e65/swa-min.jpg" />
                <div className="u-list u-repeater u-list-1">
                    <div className="u-align-left u-container-style u-gradient u-list-item u-repeater-item u-shape-round u-list-item-1">
                        <div className="u-container-layout u-similar-container u-container-layout-1">
                            <div className="u-align-center u-container-style u-group u-opacity u-opacity-65 u-palette-5-dark-1 u-radius-50 u-shape-round u-group-1">
                                <div className="u-container-layout u-valign-middle u-container-layout-2">
                                    <h1 className="u-custom-font u-font-oswald u-text u-text-1">01</h1>
                                </div>
                            </div>
                            <h5 className="u-align-center u-text u-text-body-alt-color u-text-2">QUALITÀ</h5>
                            <p className="u-text u-text-default u-text-grey-30 u-text-3">La qualità dei nostri servizi ci rende unici ad altri competitor.</p>
                        </div>
                    </div>
                    <div className="u-align-left u-container-style u-gradient u-list-item u-repeater-item u-shape-round u-list-item-2">
                        <div className="u-container-layout u-similar-container u-container-layout-3">
                            <div className="u-align-center u-container-style u-group u-opacity u-opacity-65 u-palette-5-dark-1 u-radius-50 u-shape-round u-video-cover u-group-2">
                                <div className="u-container-layout u-valign-middle u-container-layout-4">
                                    <h1 className="u-custom-font u-font-oswald u-text u-text-default u-text-4">02</h1>
                                </div>
                            </div>
                            <h5 className="u-align-center u-text u-text-body-alt-color u-text-5">AFFIDABILITÀ</h5>
                            <p className="u-text u-text-default u-text-grey-30 u-text-6">I nostri sistemi sono online al 99.9%.</p>
                        </div>
                    </div>
                    <div className="u-align-left u-container-style u-gradient u-list-item u-repeater-item u-shape-round u-list-item-3">
                        <div className="u-container-layout u-similar-container u-container-layout-5">
                            <div className="u-align-center u-container-style u-group u-opacity u-opacity-65 u-palette-5-dark-1 u-radius-50 u-shape-round u-video-cover u-group-3">
                                <div className="u-container-layout u-valign-middle u-container-layout-6">
                                    <h1 className="u-custom-font u-font-oswald u-text u-text-default u-text-7">03</h1>
                                </div>
                            </div>
                            <h5 className="u-align-center u-text u-text-body-alt-color u-text-8">FIDUCIA</h5>
                            <p className="u-text u-text-default u-text-grey-30 u-text-9">Tutti i nostri short link sono generati con HTTPS, sicuri ed affidabili.</p>
                        </div>
                    </div>
                    <div className="u-align-left u-container-style u-gradient u-list-item u-repeater-item u-shape-round u-list-item-3">
                        <div className="u-container-layout u-similar-container u-container-layout-5">
                            <div className="u-align-center u-container-style u-group u-opacity u-opacity-65 u-palette-5-dark-1 u-radius-50 u-shape-round u-video-cover u-group-3">
                                <div className="u-container-layout u-valign-middle u-container-layout-6">
                                    <h1 className="u-custom-font u-font-oswald u-text u-text-default u-text-7">04</h1>
                                </div>
                            </div>
                            <h5 className="u-align-center u-text u-text-body-alt-color u-text-8">INNOVAZIONE</h5>
                            <p className="u-text u-text-default u-text-grey-30 u-text-12">Siamo sempre alla ricerca di nuove tecnologie per offrire il meglio ai nostri clienti.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="u-clearfix u-section-2" id="carousel_d474">
                <div className="u-clearfix u-sheet u-valign-middle-lg u-valign-middle-md u-valign-middle-xl u-sheet-1">
                    <div className="u-clearfix u-expanded-width u-gutter-30 u-layout-wrap u-layout-wrap-1">
                        <div className="u-layout">
                            <div className="u-layout-row">
                                <div className="u-align-left u-container-style u-expand-resize u-layout-cell u-left-cell u-size-24 u-layout-cell-1">
                                    <div className="u-container-layout u-container-layout-1">
                                        <div className="u-container-style u-expanded-width-md u-group u-palette-1-light-1 u-group-1">
                                            <div className="u-container-layout u-valign-middle u-container-layout-2">
                                                <h1 className="u-custom-font u-text u-text-1">SOS Link</h1>
                                            </div>
                                        </div>
                                        <p className="u-text u-text-2">Siamo un team giovane che ha voglia di mettersi in gioco per offrire un servizio che sia affidabile, facile da usare e soprattutto sicuro.<br />Offriamo ai nostri clienti prodotti sempre innovativi, all'avanguardia ma soprattutto di qualità.</p>
                                    </div>
                                </div>
                                <div className="u-container-style u-layout-cell u-right-cell u-size-36 u-layout-cell-2">
                                    <div className="u-container-layout u-container-layout-3">
                                        <div className="u-container-style u-group u-palette-1-light-1 u-group-2">
                                            <div className="u-container-layout u-container-layout-4"></div>
                                        </div>
                                        <div className="u-container-style u-group u-image u-image-tiles u-image-1">
                                            <div className="u-container-layout u-valign-bottom u-container-layout-5"></div>
                                        </div>
                                        <img src="https://images03.nicepage.io/b099b0aad6b7363fee7a1479/ae48310c12435edab6673019/2361670_medium2000.jpg" className="u-align-left u-image u-image-2" data-image-width="150" data-image-height="97" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer className="u-align-center u-clearfix u-footer u-grey-80 u-footer" id="sec-8874"><div className="u-clearfix u-sheet u-sheet-1">
                <p className="u-small-text u-text u-text-variant u-text-1">SOS Link 2020</p>
            </div></footer>
        </div>
    )
}

export default HomePage;