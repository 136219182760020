import Axios from "axios";

interface IProps {
    token:   string,
    newlink: string
}

interface IRes {
    id: string,
    short_link: string,
    long_link: string
}

async function DeleteLink(props:IProps) {

    let res:IRes = {
        id:         "",
        short_link: "",
        long_link:  ""
    }

    try {
        await
            Axios.request({
                method: 'POST',
                url: 'https://soslink.it/api/add_new_link',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json; charset=UTF-8",
                },
                data: JSON.stringify({
                    token:  props.token,
                    link:   props.newlink
                })
            }).then((response) => {
                if(response.data !== "")
                    res = response.data
                // console.log(response)
            })
    } catch (err) {
        console.log(err);
    }

    return(res) 
}

export default DeleteLink;