import Axios from "axios";

interface IProps {
    token: string
}

async function LogOut(props:IProps) {

    let result: string = "";

    try {
        await
            Axios.request({
                method: 'POST',
                url: 'https://soslink.it/api/logout',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json; charset=UTF-8",
                },
                data: JSON.stringify({
                    token: props.token
                })
            }).then((response) => {
                localStorage.removeItem("credential")
                result = "LogOut con Successo!"
            })
    } catch (err) {
        console.log(err);
    }    

    return(result)
}

export default LogOut;